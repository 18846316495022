import React from "react";
import styled from "styled-components";
import brandLight from "./logo.png";
// import brandDark from "./logo-dark.png";
import brandLogoLight from "./pixodox-logo.png";
import { useNavigate } from "react-router";

const MainBrand = styled.div`
  img {
    width: ${(props) => props.width};
    max-width: 100%;
    height: ${(props) => props.height};
  }
  .v-light &,
  & {
    .logo-dark {
      display: block;
    }
    .logo-light {
      display: none;
    }
  }

  .v-dark & {
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }
`;

const Brand = ({ width, height, alt, logo, logoName }) => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {logo ? (
        <img
          src={brandLogoLight}
          alt={""}
          width={"45px"}
          height={"auto"}
          loading="lazy"
          onClick={() => navigate("/home")}
        />
      ) : null}
      <MainBrand className="main-brand" width={width} height={height}>
        {logoName ? (
          <img
            className="logo-light"
            src={brandLight}
            alt={`${alt} - logo light`}
            width={width}
            height={height}
            onClick={() => navigate("/home")}
          />
        ) : null}
        {/* <img
          className="logo-dark"
          src={brandDark}
          alt={`${alt} - logo dark`}
          width={width}
          height={height}
          onClick={() => navigate("/home")}
        /> */}
      </MainBrand>
    </div>
  );
};

MainBrand.defaultProps = { width: "80px", height: "auto" };
Brand.defaultProps = { alt: "Pixodox" };

export default React.memo(Brand);
