import React, { useLayoutEffect, useState } from "react";
// Importing the Bootstrap CSS
import "swiper/css/pagination";

import "bootstrap/dist/css/bootstrap-grid.css";

import Router from "./router/Router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dsnEffect, {
  fade,
  moveSection,
  textAnimation,
} from "./animation/DsnEffect";
import Layout from "./layout/Layout";
import { BrowserRouter } from "react-router";
import { useDispatch } from "react-redux";
import { setScrollbar } from "./features/smooth-scrollbar/smoothScrollbarSlice";

function App() {
  const [activeScrollbar, setActiveScrollbar] = useState(true);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    dsnEffect.registerEffect(moveSection, fade, textAnimation);

    gsap.config({
      nullTargetWarn: false,
    });
    gsap.defaults({
      ease: "none",
      duration: 1,
      overwrite: "auto",
    });
  }, []);

  const handleClickScroll = () => {
    setActiveScrollbar(!activeScrollbar);
    if (activeScrollbar) dispatch(setScrollbar(null));
  };

  return (
    <BrowserRouter>
      <Layout
        className="background-main"
        tag="main"
        activeScrollbar={activeScrollbar}
        handleClickScroll={handleClickScroll}
        colorVersion="dark"
        option={{ damping: 0.05, plugins: { effect: "bounce" } }}
      >
        <Router />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
